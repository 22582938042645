import { Component } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ml-ui-services';

  WHATSAPP_ICON = `
  <?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2315 4795 c-440 -51 -843 -227 -1196 -523 -155 -130 -341 -349 -450
-528 -400 -662 -437 -1461 -100 -2146 l67 -137 -157 -573 c-87 -315 -157 -574
-156 -575 2 -1 267 68 590 153 l588 154 60 -31 c365 -184 809 -268 1208 -230
704 69 1318 447 1691 1041 67 106 168 317 210 435 217 619 156 1297 -167 1854
-297 512 -791 895 -1344 1041 -267 71 -584 95 -844 65z m-377 -1196 c17 -6 38
-22 47 -35 37 -56 207 -480 208 -519 2 -48 -25 -96 -118 -208 -36 -43 -65 -86
-65 -95 0 -21 53 -112 120 -204 70 -99 254 -276 359 -346 106 -71 284 -160
336 -169 49 -8 68 8 179 146 53 66 106 126 118 132 32 17 75 1 335 -126 203
-99 233 -117 239 -140 10 -40 -2 -141 -27 -220 -19 -61 -30 -78 -88 -135 -113
-112 -259 -170 -422 -170 -111 0 -195 18 -356 75 -333 120 -584 282 -829 538
-227 236 -415 505 -484 689 -73 197 -65 407 21 569 41 77 143 187 194 210 46
21 182 26 233 8z"/>
</g>
</svg>

`;



  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('whatsapp', sanitizer.bypassSecurityTrustHtml(this.WHATSAPP_ICON))
  }
}
