export interface Config {
  environment: Environments;
  application: Aplication;
}

export enum Environments {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'stage',
  PRODUCTION = 'production',
}

export interface Aplication {
  key?: string;
  name?: string;
  callbackUrl?: string;
  origin?: string;
}
